<script setup>
/* eslint-disable no-unused-vars */
import { onMounted, ref } from "vue";
import * as generalAPI from "@/http/general";
import { ElNotification } from "element-plus";
import { getSubscriptionDetails } from "@/http/subscription";

const defaultSellingOption = {
  likes: null,
  price: null,
  commission: null,
};
const defaultSubscriptionOption = {
  chat: null,
  created_at: null,
  duration_in_months: null,
  extra_favorites: null,
  extra_photos: null,
  id: null,
  phone_numbers: null,
  price: null,
  updated_at: null,
};
const img_url = ref();

const show = ref(true);

const setting = ref({
  invisible_mode: null,
  redactor_commission: null,
});

const OneMonthSubscription = ref({
  ...defaultSubscriptionOption,
});
const threeMonthSubscription = ref({
  ...defaultSubscriptionOption,
});

const firstSellingOption = ref({
  ...defaultSellingOption,
});
const secondSellingOption = ref({
  ...defaultSellingOption,
});
const thirdSellingOption = ref({
  ...defaultSellingOption,
});

onMounted(() => {
  getSubscriptionDetail();
  getNumberSelling();
  getSetting();
});

const getSetting = async () => {
  await generalAPI.getSettings().then((res) => {
    setting.value.invisible_mode = res.data[4].value;
    setting.value.redactor_commission = res.data[5].value;
  });
};

const setSetting = async (id, data) => {
  await generalAPI
    .setSettings(id, { value: data })
    .then((res) => {
      ElNotification({
        message: "Paramètre modifié !",
        type: "success",
      });
    })
    .catch(() => {
      ElNotification({
        message: "Paramètre non modifié, veuillez réessayer !",
        type: "error",
      });
    });
};

const getSubscriptionDetail = async () => {
  await getSubscriptionDetails().then((res) => {
    OneMonthSubscription.value = res.data[0];
    threeMonthSubscription.value = res.data[1];
  });
};

const getNumberSelling = async () => {
  await generalAPI.getNumberSelling().then((res) => {
    firstSellingOption.value = res[0];
    secondSellingOption.value = res[1];
    thirdSellingOption.value = res[2];
  });
};

const handleSubscriptionUpdate = async (subscription) => {
  await generalAPI
    .editSubscriptionDetails(subscription.id, subscription)
    .then((res) => {
      ElNotification({
        message: "Paramètre modifié !",
        type: "success",
      });
    })
    .catch(() => {
      ElNotification({
        message: "Paramètre non modifié, veuillez réessayer !",
        type: "error",
      });
    });
};

const handleSellingUpdate = async (selling) => {
  await generalAPI
    .editNumberSelling(selling.id, selling)
    .then((res) => {
      ElNotification({
        message: "Paramètre modifié !",
        type: "success",
      });
    })
    .catch(() => {
      ElNotification({
        message: "Paramètre non modifié, veuillez réessayer !",
        type: "error",
      });
    });

  getNumberSelling();
};

const handleCertificationImage = async (file) => {
  if (file.size > 1000000) {
    ElNotification({
      title: "Erreur",
      message: "La taille de l'image ne doit pas excéder 1MB",
      type: "error",
    });
  } else {
    const formData = new FormData();
    formData.append("image", file.raw);
    await generalAPI
      .postCertificationImage(formData)
      .then(() => {
        show.value = false;
        show.value = true;
        ElNotification({
          message: "Paramètre modifié !",
          type: "success",
        });
      })
      .catch(() => {
        ElNotification({
          message: "Paramètre non modifié, veuillez réessayer !",
          type: "error",
        });
      });
  }
};
</script>

<template>
  <el-card class="box-card">
    <el-form :inline="true" label-position="top">
      <el-form-item size="large" label="Premium 1 mois">
        <el-input
          @blur="handleSubscriptionUpdate(OneMonthSubscription)"
          v-model.number="OneMonthSubscription.price"
        />
      </el-form-item>
      <el-form-item size="large" label="Premium 3 mois">
        <el-input
          @blur="handleSubscriptionUpdate(threeMonthSubscription)"
          v-model.number="threeMonthSubscription.price"
        />
      </el-form-item>
      <el-form-item size="large" label="Mode invisible (FCFA/Mois)">
        <el-input
          @blur="setSetting(5, setting.invisible_mode)"
          v-model.number="setting.invisible_mode"
        />
      </el-form-item>
      <el-form-item size="large" label="Commission rédacteur (%)">
        <el-input
          @blur="setSetting(6, setting.redactor_commission)"
          v-model.number="setting.redactor_commission"
        />
      </el-form-item>
    </el-form>

    <div>
      <hr />
      <h6>Vente numéro - Option 1</h6>
      <el-form :inline="true">
        <el-form-item size="large" label="Likes">
          <el-input
            @blur="handleSellingUpdate(firstSellingOption)"
            v-model.number="firstSellingOption.likes"
            placeholder="Saisir le nombre"
          />
        </el-form-item>
        <el-form-item size="large" label="Prix">
          <el-input
            @blur="handleSellingUpdate(firstSellingOption)"
            v-model.number="firstSellingOption.price"
            placeholder="Saisir le coût"
          />
        </el-form-item>
        <el-form-item size="large" label="Commission client ( F CFA )">
          <el-input
            @blur="handleSellingUpdate(firstSellingOption)"
            v-model.number="firstSellingOption.commission"
            placeholder="Saisir le coût"
          />
        </el-form-item>
      </el-form>
      <hr />
      <h6>Vente numéro - Option 2</h6>
      <el-form :inline="true">
        <el-form-item size="large" label="Likes">
          <el-input
            @blur="handleSellingUpdate(secondSellingOption)"
            v-model.number="secondSellingOption.likes"
            placeholder="Saisir le nombre"
          />
        </el-form-item>
        <el-form-item size="large" label="Prix">
          <el-input
            @blur="handleSellingUpdate(secondSellingOption)"
            v-model.number="secondSellingOption.price"
            placeholder="Saisir le coût"
          />
        </el-form-item>
        <el-form-item size="large" label="Commission client ( F CFA )">
          <el-input
            @blur="handleSellingUpdate(secondSellingOption)"
            v-model.number="secondSellingOption.commission"
            placeholder="Saisir le coût"
          />
        </el-form-item>
      </el-form>
      <hr />
      <h6>Vente numéro - Option 3</h6>
      <el-form :inline="true">
        <el-form-item size="large" label="Likes">
          <el-input
            @blur="handleSellingUpdate(thirdSellingOption)"
            v-model.number="thirdSellingOption.likes"
            placeholder="Saisir le nombre"
          />
        </el-form-item>
        <el-form-item size="large" label="Prix">
          <el-input
            @blur="handleSellingUpdate(thirdSellingOption)"
            v-model.number="thirdSellingOption.price"
            placeholder="Saisir le coût"
          />
        </el-form-item>
        <el-form-item size="large" label="Commission client ( F CFA )">
          <el-input
            @blur="handleSellingUpdate(thirdSellingOption)"
            v-model.number="thirdSellingOption.commission"
            placeholder="Saisir le coût"
          />
        </el-form-item>
      </el-form>
    </div>

    <hr />
    <h6>Image de vérification de profil</h6>
    <el-form class="d-flex">
      <el-upload
        class="w-100"
        accept="image/*"
        :auto-upload="false"
        @change="handleCertificationImage"
        :multiple="false"
        :show-file-list="false"
        drag
      >
        <img v-if="show" width="350" :src="generalAPI.getImageUrl()" />
        <div class="el-upload__text">
          Glisser une image ici ou <em>Cliquer pour sélectionner</em>
        </div>
        <template #tip>
          <div class="el-upload__tip">Image jpg/png de moins de 1MB</div>
        </template>
        <!-- <template #file="{ file }">
          <div>
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <el-icon><zoom-in /></el-icon>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleDownload(file)"
              >
                <el-icon><Download /></el-icon>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <el-icon><Delete /></el-icon>
              </span>
            </span>
          </div>
        </template> -->
      </el-upload>
    </el-form>
  </el-card>
</template>

<style scoped>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
}

.label {
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 5px;
  background: rgba(38, 150, 190, 0.2);
  color: rgb(38, 150, 190);
}
</style>
